import BlurFade from "@/components/magicui/blur-fade";
import Section from "@/components/section";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Image from "next/image";
import { MdOutlineFormatQuote, MdStar } from "react-icons/md";
import placeholderLogo from "@/assets/fugro-logo.svg";

const companies = [
  "Google",
  "Microsoft",
  "Amazon",
  "Netflix",
  "YouTube",
  "Instagram",
  "Uber",
  "Spotify",
];

export default function Component() {
  return (
   <Section
    id="testimonials"
    title="Here is what Geo-data experts say"
    className="bg-secondary-foreground bg-opacity-10 overflow-hidden"
  >
      <Carousel>
        <div className="max-w-2xl mx-auto">
          <CarouselContent>
          <CarouselItem key={1}>
                <div className="p-1">
                  <div className=" text-center">
                    <div className="flex justify-center my-4">
                      {[...Array(5)].map((_, index) => (
                        <MdStar key={index} className="text-yellow-500 text-xl mx-1" />
                      ))}
                    </div>
                    <MdOutlineFormatQuote className="text-4xl text-themeDarkGray my-4 mx-auto" />
                    <BlurFade delay={0.25} inView>
                      <h4 className="text-1xl font-semibold break-words">
                      The Geo-data Intelligence platform streamlines geotechnical processes and reduces work time by 50-60%. 
                      It’s an all-in-one solution that we’ll definitely consider for future projects due to its 
                      ability to digitize and provide actionable insights effortlessly.
                      </h4>
                    </BlurFade>
                    <div className="">
                      <BlurFade delay={0.25 * 3} inView>
                        <h4 className="text-1xl font-semibold my-2">
                          Matthew A.
                        </h4>
                      </BlurFade>
                    </div>
                    <BlurFade delay={0.25 * 4} inView>
                      <div className=" mb-3">
                        <span className="text-sm text-themeDarkGray">
                          Geotechnical Engineer
                        </span>
                      </div>
                    </BlurFade>
                  </div>
                </div>
              </CarouselItem>
              <CarouselItem key={2}>
                <div className="p-1">
                  <div className=" text-center">
                    <div className="flex justify-center my-4">
                      {[...Array(5)].map((_, index) => (
                        <MdStar key={index} className="text-yellow-500 text-xl mx-1" />
                      ))}
                    </div>
                    <MdOutlineFormatQuote className="text-4xl text-themeDarkGray my-4 mx-auto" />
                    <BlurFade delay={0.25} inView>
                      <h4 className="text-1xl font-semibold break-words">
                      Smart solution, help users to greatly decrease the time required for preparing factual and interpretation 
                      reports as well as it helps to avoid any human mistakes in typing reports, 
                      collect the data in one platform that will be useful to review and secure it.
                      </h4>
                    </BlurFade>
                    <div className="">
                      <BlurFade delay={0.25 * 3} inView>
                        <h4 className="text-1xl font-semibold my-2">
                          Dr. Mohamed F.
                        </h4>
                      </BlurFade>
                    </div>
                    <BlurFade delay={0.25 * 4} inView>
                      <div className=" mb-3">
                        <span className="text-sm text-themeDarkGray">
                          Assistant Geotechnical Manager
                        </span>
                      </div>
                    </BlurFade>
                  </div>
                </div>
              </CarouselItem>
              <CarouselItem key={3}>
                <div className="p-1">
                  <div className=" text-center">
                    <div className="flex justify-center my-4">
                      {[...Array(5)].map((_, index) => (
                        <MdStar key={index} className="text-yellow-500 text-xl mx-1" />
                      ))}
                    </div>
                    <MdOutlineFormatQuote className="text-4xl text-themeDarkGray my-4 mx-auto" />
                    <BlurFade delay={0.25} inView>
                      <h4 className="text-1xl font-semibold break-words">
                      The ability to transform reports into workable data is truly remarkable. 
                      Having all our data digitized in one place not only saves time but also 
                      adds tremendous value when we start new projects.
                      </h4>
                    </BlurFade>
                    <div className="">
                      <BlurFade delay={0.25 * 3} inView>
                        <h4 className="text-1xl font-semibold my-2">
                          Joseph G.
                        </h4>
                      </BlurFade>
                    </div>
                    <BlurFade delay={0.25 * 4} inView>
                      <div className=" mb-3">
                        <span className="text-sm text-themeDarkGray">
                          Geotechnical Engineer
                        </span>
                      </div>
                    </BlurFade>
                  </div>
                </div>
              </CarouselItem>
          </CarouselContent>
        </div>
        <div className="md:block hidden">
          <CarouselPrevious />
          <CarouselNext />
        </div>
      </Carousel>
    </Section>
  );
}
